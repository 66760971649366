import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { forwardRef, useState } from "react";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactModal({ handleClose }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        handleClose();
        setOpen(true);
    };

    const handleCloseDig = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleClickOpen} sx={{ my: 2, color: 'white', display: 'block' }}>
                Contact US
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDig}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Want To Get Online ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Pankaj Sarda
                        +91 77 41 88 99 11
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}