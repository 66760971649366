import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export default function Hero() {
    return (
        <Grid container alignItems={"center"} sx={{ height: "100vh" }}>
            <Grid xs={12} md={6}>
                <img className='responsive' src='./1.png' alt='Test' />
            </Grid>
            <Grid xs={12} md={6}>
                <Typography variant='h1' color={"#8a2be2"}>
                    Your Business
                </Typography>
                <Typography variant='h2' align='center'>
                    As A Service...
                </Typography>
            </Grid>
        </Grid>
    )
}