import { CssBaseline, createTheme } from "@mui/material";
import Menubar from "./Menubar";
import { useState } from "react";
import { ThemeProvider } from "@emotion/react";
import Hero from "./Hero";

const DARK_THEME_MODE = "dark";
const LIGHT_THEME_MODE = "light"

const darkTheame = createTheme({
  palette: {
    mode: DARK_THEME_MODE
  }
});

const lightTheame = createTheme({
  palette: {
    mode: LIGHT_THEME_MODE
  }
});

function App() {
  const [theme, setTheme] = useState(darkTheame);

  const toggleTheme = () => {
    theme.palette.mode === DARK_THEME_MODE ? setTheme(lightTheame) : setTheme(darkTheame);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Menubar toggleTheme={toggleTheme} />
      <Hero />
    </ThemeProvider>
  );
}

export default App;
