import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function Logo({ sx, small = false }) {
    const theme = useTheme();

    const img_height = small ? "40px" : "50px";
    return (
        <Box sx={sx}>
            {
                theme.palette.mode === "dark"
                    ? <img height={img_height} src=".\OPANKYS_BLACK.png" alt="Test" />
                    : <img height={img_height} src=".\OPANKYS_WHITE.png" alt="Test" />
            }
        </Box>
    );
}